<template>
    <div>
        <TableLayout
            :module="module"
            ref="TableLayout"
            :table-columns="tableColumns"
            initSortField="isin"
            initSortOrder="asc"
            :rights="rights"
            :filters="filters"
            :additionalOperButtonsWidth="40"
            no-table-del
            no-table-edit
            v-if="loaded">

            <template v-slot:additional_oper_buttons="{row}">
                <el-button
                    type="text"
                    @click="openItem(row)"
                    size="mini"
                    class="tl-oper_button oper_button_instrument">
                    <svg-icon icon-class="chevron_right" />
                </el-button>
            </template>

        </TableLayout>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    components: { TableLayout },
    mixins: [tab],

    data() {
        return {
            module: 'instrumentCurrency',
            loaded: false,
            rights: {
                view : true,
                edit : false,
                add  : false,
                del  : false,
            },
            filters: {
                code : { value: undefined },
                name : { value: undefined },
            },
            currency_options: []
        }
    },

    computed: {

        tableColumns(){
            let columns = [
                {
                    field  : 'code', 
                    title  : 'CODE', 
                    width  : 130, 
                    fixed  : 'left',
                    params : {
                        filter : 'input', 
                    }, 
                },{
                    field  : 'name', 
                    title  : 'Name', 
                    minWidth  : 100, 
                    params : {
                        filter : 'input', 
                    }, 
                },
            ]

            return columns
        }
    },

    methods: {
        openItem(row) {
            //this.$router.push('/library/instruments/currency/' + row.code)
        }
    },

    mounted(){
        this.loaded = true
    },
}

</script>
